export default {
  navigationV6: {
    container: {
      position: ['fixed', 'fixed', 'fixed', 'fixed'],
      flexWrap: ['wrap', '', '', 'nowrap'],
      width: '100%',
      top: '0px',
      left: '0px',
      zIndex: '1000',
      boxSizing: 'border-box',
      flexDirection: ['', '', '', 'row'],
      minHeight: ['75px'],
      maxWidth: ['', '', '', ''],
      padding: ['0.5rem', , '0.25rem'],
      display: 'flex',
      transition: 'all ease-in-out 0.5s',
      alignItems: 'center',
      justifyContent: ['flex-end', '', '', 'flex-start']
    },

    containerScrolled: {
      variant: 'navigationV6.container',
      flexWrap: ['wrap', '', '', 'nowrap'],
      // background: [
      //   // 'linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.33) 50%, rgba(255,255,255,0) 100%)',
      //   'black',
      //   '',
      //   '',
      //   'unset',
      // ],
      backgroundColor: ['black', '', '', 'background'],
      li: {
        a: {
          color: ['', '', '', 'text']
        }
      }
    },

    locationSwitcher: {
      backgroundColor: '',
      position: ['fixed', , , 'unset'],
      left: ['0'],
      top: ['35px'],
      transform: ['translateY(-50%)', , , 'unset'],
      zIndex: '2000'
    },

    logo: {
      maxWidth: ['200px', '', '', '300px']
    },

    logoScrolled: {},

    hamburger: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        alignSelf: 'flex-end',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '10px'
      }
    },
    hamburgerOpen: {
      position: 'relative',
      display: ['flex', 'flex', 'flex', 'none'],
      zIndex: '2000',
      alignSelf: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '35px',
      height: '25px',
      cursor: 'pointer',
      margin: '0 0.5rem 0 auto',
      div: {
        position: 'absolute',
        boxShadow: 'none',
        transition: 'all ease-in-out 0.3s',
        height: '4px',
        width: '100%',
        backgroundColor: 'text',
        boxShadow: 'none',
        borderRadius: '10px'
      },
      '& > :nth-child(1)': {
        transform: 'rotate(135deg) '
      },
      '& > :nth-child(2)': {
        transform: 'rotate(-135deg)'
      }
    },
    navMenu: {
      width: ['100%'],
      overflow: 'hidden',
      height: ['0px', '', '', '100%'],
      flexGrow: ['', '', '', '1'],
      justifyContent: ['', '', '', 'flex-end'],
      flexDirection: ['column', 'column', 'column', 'row']
    },
    navMenuOpen: {
      variant: 'navigationV6.navMenu',
      height: ['100%', '', '', '100%']
    },

    navItem: {
      zIndex: '100',
      margin: ['0.75rem', '', '', '0.75rem'],
      listStyleType: 'none',
      margin: '0',
      borderBottom: ['1px solid lightgrey', '', '', 'none'],
      transition: 'all ease-in-out 0.4s',
      ':hover': {
        backgroundColor: 'primary',
        color: 'light'
      },
      a: {
        display: 'flex',
        padding: '1rem',
        fontSize: ['1rem', '1rem', '1.25rem', '1rem'],
        textTransform: 'uppercase',
        fontWeight: '400',
        fontWeight: ['', '', '', 'bold'],
        color: ['', '', '', 'light'],
        letterSpacing: '2px',
        textDecoration: 'none'
      },
      ':last-child': {
        borderBottom: ['none', '', '', 'none']
      }
    },
    socialIconsContainer: {
      margin: ['1rem 0rem 0rem', , , '0rem 1.5rem']
    },
    phoneContainer: {
      margin: ['1rem 0rem 0rem', , , '0rem 1.5rem']
    }
  }
}
